


































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import ServiceLevelForm from './servicelevel-form.vue'
import SortingModel from "@/store/entities/sortingmodel";

class PageServiceLevelRequest extends PageRequest {
}

@Component({
    components: {ServiceLevelForm}
})
export default class ServiceLevel extends AbpBase {
    edit() {
        this.editModalShow = true;
    }

    //filters
    pagerequest: PageServiceLevelRequest = new PageServiceLevelRequest();

    createModalShow: boolean = false;
    editModalShow: boolean = false;

    get list() {
        return this.$store.state.servicelevel.list;
    };

    get loading() {
        return this.$store.state.servicelevel.loading;
    }

    async search() {
        this.$store.commit('servicelevel/setCurrentPage', 1);
        await this.getpage();
    }

    create() {
        this.createModalShow = true;
    }

    async changeSort(data) {
        if (this.pagerequest.sorting == null) {
            this.pagerequest.sorting = [];
        }
        let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
        if (item == null) {
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pagerequest.sorting.push(sortingModel)
        } else {
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getpage()
    }

    pageChange(page: number) {
        this.$store.commit('servicelevel/setCurrentPage', page);
        this.getpage();
    }

    pagesizeChange(pagesize: number) {
        this.$store.commit('servicelevel/setPageSize', pagesize);
        this.getpage();
    }

    async getpage() {

        this.pagerequest.maxResultCount = this.pageSize;
        this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'servicelevel/getAll',
            data: this.pagerequest
        })
    }

    get pageSize() {
        return this.$store.state.servicelevel.pageSize;
    }

    get totalCount() {
        return this.$store.state.servicelevel.totalCount;
    }

    get currentPage() {
        return this.$store.state.servicelevel.currentPage;
    }

    columns = [{
        title: this.L('ServiceLevel'),
        key: 'name',
        sortable: 'custom'
    }, {
        title: this.L('Code'),
        key: 'code',
        sortable: 'custom'
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 150,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        level: 'primary',
                        size: 'small',
                        disabled: !this.hasEditPermission()
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: () => {
                            this.$store.commit('servicelevel/edit', params.row);
                            this.edit();
                        }
                    }
                }, this.L('Edit')),
                h('Button', {
                    props: {
                        level: 'error',
                        size: 'small',
                        disabled: !this.hasDeletePermission()
                    },
                    on: {
                        click: async () => {
                            this.$Modal.confirm({
                                title: this.L('Tips'),
                                content: this.L('Delete Service level Confirm'),
                                okText: this.L('Yes'),
                                cancelText: this.L('No'),
                                onOk: async () => {
                                    await this.$store.dispatch({
                                        type: 'servicelevel/delete',
                                        data: params.row
                                    })
                                    await this.getpage();
                                }
                            })
                        }
                    }
                }, this.L('Delete'))
            ])
        }
    }]

    async created() {
        await this.getpage();
    }

    hasAddPermission(){
        return this.hasPermission("Pages.ServiceLevels.Add");
    }

    hasEditPermission(){
        return this.hasPermission("Pages.ServiceLevels.Edit");
    }

    hasDeletePermission(){
        return this.hasPermission("Pages.ServiceLevels.Delete");
    }
}
